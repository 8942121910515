<template>
    <LiefengContent class="vote">
        <template v-slot:title>答题活动</template>
        <template v-slot:toolsbarRight>
            <Button type="primary" @click="addVote" :style="{ display: buttonRoot == '1001' ? '' : 'none' }" icon="ios-add">新增</Button>
            <Button @click="exportExcel" type="error" icon="ios-cloud-download-outline">导出</Button>
            <Button type="info" @click="tikuFn">
                <Icon type="ios-school-outline" />
                题库管理
            </Button>
            <Button @click="moreDel" type="success" icon="ios-trash-outline">批量删除</Button>
        </template>
        <template v-slot:contentArea>
            <div class="table-left">
                <Menu theme="light" :active-name="activeMenu" @on-select="selectMenu">
                    <MenuGroup title="信息状态">
                        <MenuItem name="2" v-if="buttonRoot == '1001'">待提交/已驳回({{ status2 }})</MenuItem>
                        <MenuItem name="1" v-if="buttonRoot == '1002'">待审核({{ status1 }})</MenuItem>
                        <MenuItem name="3" v-if="buttonRoot == '1003'">待发布({{ status3 }})</MenuItem>
                        <MenuItem name="4">
                            已发布
                            <Tooltip placement="right" content="已发布的内容居民在小程序、APP等终端可正常查看到。"><Icon type="ios-help-circle-outline" size="20" /></Tooltip>
                        </MenuItem>
                        <MenuItem name="5">
                            已过期
                            <Tooltip placement="right" content="到了截止时间内容则为已过期，在小程序的全部和往期回顾中可查看到。"><Icon type="ios-help-circle-outline" size="20" /></Tooltip>
                        </MenuItem>
                        <!-- <MenuItem name="6">已归档<Tooltip :max-width="500" placement="right" content="归档后的内容在小程序、APP等终端则不显示，对用户不可见。><Icon type="md-help" /></Tooltip></MenuItem> -->

                        <MenuItem name="7">全部信息</MenuItem>
                    </MenuGroup>
                </Menu>
            </div>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                :total="total"
                :curPage="currentPage"
                @hadlePageSize="pageChange"
                :loading="loading"
                :fixTable="true"
                :pageSize="pageSize"
                @tableSelect="tableSelect"
                @tableSort="sortChangeFn"
                :pagesizeOpts="[20, 30, 50, 100]"
            ></LiefengTable>

            <!-- 新增/修改弹窗 -->
            <LiefengModal :title="addChangeTitle" :value="addChangeModalStatus" :fullscreen="true" @input="addChangeModalFn">
                <template v-slot:contentarea>
                    <AddVote
                        ref="addVote"
                        @addChangeModalFn="addChangeModalFn"
                        @pageChange="pageChange"
                        @comSelectMenu="comSelectMenu"
                        :menuCodeObj="menuCodeObj"
                        v-if="addVoteStatus && addChangeModalStatus"
                    ></AddVote>
                    <ChangeVote
                        ref="changeVote"
                        :infoId="infoId"
                        @addChangeModalFn="addChangeModalFn"
                        @pageChange="pageChange"
                        @comSelectMenu="comSelectMenu"
                        :menuCodeObj="menuCodeObj"
                        @messageStatusCount="messageStatusCount"
                        v-if="changeVoteStatus && addChangeModalStatus"
                    ></ChangeVote>
                    <PublishVote
                        ref="publishVote"
                        :infoId="infoId"
                        @modalStatusFn="modalStatusFn"
                        @addChangeModalFn="addChangeModalFn"
                        @pageChange="pageChange"
                        @comSelectMenu="comSelectMenu"
                        :menuCodeObj="menuCodeObj"
                        @messageStatusCount="messageStatusCount"
                        v-if="publishVoteStatus && addChangeModalStatus"
                        :orgCode="orgCode"
                    ></PublishVote>
                </template>
                <template v-slot:toolsbar>
                    <Button type="primary" @click="backStep" :disabled="disableBtn == 0 ? true : false">上一步</Button>
                    <Button type="primary" @click="nextStep" style="margin-left: 10px" :disabled="disableBtn == 2 ? true : false">下一步</Button>
                    <Button type="success" @click="saveVote" style="margin-left: 10px" :style="{ display: buttonRoot == '1001' ? '' : 'none' }">保存</Button>
                    <!--          <Button type="warning" style="margin-left: 10px"-->
                    <!--                  :style="{ display: buttonRoot == '1001' ? '' : 'none' }"-->
                    <!--                  @click="contentFn">预览文本</Button>-->
                    <Button
                        type="error"
                        @click="rejectVote"
                        style="margin-left: 10px"
                        :style="{
                            display: buttonRoot == '1002' || buttonRoot == '1003' ? '' : 'none',
                        }"
                    >
                        驳回
                    </Button>
                    <Button
                        type="warning"
                        @click="saveVote"
                        style="margin-left: 10px"
                        :style="{
                            display: buttonRoot == '1002' || buttonRoot == '1003' ? '' : 'none',
                        }"
                    >
                        {{ publishRightBtnStatus == "1" ? "通过" : publishRightBtnStatus == "3" ? "发布" : "过期" }}
                    </Button>
                    <Button
                        type="warning"
                        @click="passAndSend"
                        style="margin-left: 10px"
                        :style="{
                            display: buttonRoot == '1002' ? '' : 'none',
                        }"
                    >
                        通过并加急发布
                    </Button>
                </template>
            </LiefengModal>
            <!-- 查看弹窗 -->
            <LiefengModal title="查看答题" :value="seeModalStatus" :fullscreen="true" @input="seeModalModalFn">
                <template v-slot:contentarea>
                    <baseInfo :formValidate="seeFormData" v-if="seeModalStatus"></baseInfo>
                    <ruleConfig :formValidate="seeFormData" v-if="seeModalStatus"></ruleConfig>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/answerindex')
import { format } from "@/utils/formatTime"
import BaseInfo from "@/views/answer/childrens/baseInfo"
import RuleConfig from "@/views/answer/childrens/ruleConfig"
import HighSetting from "@/views/answer/childrens/highSetting"
import PublishVote from "@/views/answer/childrens/publishVote"
import ChangeVote from "@/views/answer/childrens/changeVote"
import AddVote from "@/views/answer/childrens/addVote"
import LiefengModal from "@/components/LiefengModal"
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
export default {
    data() {
        return {
            //查看弹窗
            seeModalStatus: false,
            seeFormData: {
                title: "", //标题
                source: "", //信息来源
                subtype: "", //信息类型
                appointmentPhone: "", //联系电话（预约号码）
                startTime: "", //开始时间
                subStartTime: "",
                endTime: "", //结束时间
                subEndTime: "",
                provinceCode: 44, //省编码
                cityCode: "", //城市编码
                areaCode: "", //区县编码
                streetCode: "", //街道编码
                projectCodeList: "", //社区编码
                city: "", //修改时地址
                content: "", //富文本
                photo: "", //封面图片上传路径
                settingImgPath: "", //高级设置背景图片

                voteTypeList: [], //投票方式列表

                //规则配置
                questionBankId: "", // 题库类型
                userVerified: "0", //实名认证 0 不需要 1 需要
                period: "", //周期设置 全程0 每天 24 小时 0-24 ,
                answerNumber: "", //答题局数 ,
                roundQuestionNumber: "", //每局题目数
                questionLimit: "", //每题限时 s
                participantsNumber: "", //参与人数
                winnersNumber: "", //中奖人数
                awardMethod: "", //评奖方式
                effectiveTime: "", //有效领奖开始时间 ,
                expirationTime: "", //有效领奖截止时间

                valiStartTime: "", //每天答题起始时间 格式00:00:00 ,
                valiEndTime: "", // 每天答题截止时间 格式00:00:00 ,
                whetherComment: "0", //是否开启评论 0 否 1 是 ,
                recommend: "0", //是否推荐 0 否 1是
                pushChannel: false, //小程序订阅推送通知

                modifier: parent.vue.loginInfo.userinfo.nickName, //修改人
                staffId: parent.vue.loginInfo.userinfo.id,
                status: 1,
                creatorId: parent.vue.loginInfo.userinfo.id,
            },
            //判断打开新增/修改/发布审核弹窗的状态
            addVoteStatus: false,
            changeVoteStatus: false,
            publishVoteStatus: false,
            loading: false,
            infoId: "", // 修改row id
            //publish按钮名称标识
            publishRightBtnStatus: "",
            //按钮权限
            buttonRoot: "",
            //表头列表
            talbeColumns: [
                {
                    type: "selection",
                    width: 60,
                    align: "center",
                },
                {
                    title: "序号",
                    type: "index",
                    width: 80,
                    align: "center",
                },
                {
                    title: "信息名称",
                    key: "title",
                    minWidth: 130,
                    align: "center",
                },
                {
                    title: "编码",
                    key: "code",
                    width: 90,
                    align: "center",
                },
                {
                    title: "发布范围",
                    key: "privilegeString",
                    maxWidth: 130,
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "公布时间",
                    key: "startTime",
                    width: 170,
                    align: "center",
                },
                {
                    title: "截至时间",
                    key: "endTime",
                    width: 170,
                    align: "center",
                },
                {
                    title: "拟制人",
                    key: "creatorName",
                    width: 90,
                    align: "center",
                },
                {
                    title: "推荐",
                    key: "recommend",
                    width: 165,
                    align: "center",
                    render: (h, params) => {
                        return (
                            "div",
                            [
                                h(
                                    "RadioGroup",
                                    {
                                        attrs: {
                                            value: params.row.recommend,
                                        },
                                        on: {
                                            "on-change": status => {
                                                this.infoId = params.row.id
                                                this.recommend = status
                                                this.orgCode = params.row.orgCode
                                                this.sortChangeFn()
                                            },
                                        },
                                    },
                                    [
                                        h(
                                            "Radio",
                                            {
                                                attrs: {
                                                    label: "1",
                                                    disabled: params.row.status == "已发布" || params.row.status == "已过期" ? false : true,
                                                },
                                            },
                                            "推荐"
                                        ),
                                        h(
                                            "Radio",
                                            {
                                                attrs: {
                                                    label: "0",
                                                    disabled: params.row.status == "已发布" || params.row.status == "已过期" ? false : true,
                                                },
                                            },
                                            "不推荐"
                                        ),
                                    ]
                                ),
                            ]
                        )
                    },
                },
                {
                    title: "排序",
                    key: "sort",
                    width: 110,
                    align: "center",
                    render: (h, params) => {
                        return h("InputNumber", {
                            attrs: {
                                min: 1,
                                max: 999999999,
                                value: params.row.sort,
                                precision: 0,
                            },
                            style: {
                                width: "100%",
                            },
                            on: {
                                "on-change": val => {
                                    if (val == params.row.sort) return
                                    this.recommend = params.row.recommend
                                    this.sortNum = val
                                    this.infoId = params.row.id
                                    this.orgCode = params.row.orgCode
                                    clearTimeout(this.timer)
                                    this.timer = setTimeout(() => {
                                        this.sortChangeFn()
                                    }, 1000)
                                },
                            },
                        })
                    },
                },
                {
                    title: "审核状态",
                    key: "status",
                    width: 100,
                    align: "center",
                },
                {
                    title: "操作",
                    slot: "action",
                    align: "center",
                    fixed: "right",
                    width: 180,
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    textAlign: "left",
                                },
                            },
                            [
                                h(
                                    "Button",
                                    {
                                        attrs: {
                                            type: "info",
                                            size: "small",
                                        },
                                        on: {
                                            click: () => {
                                                this.changeVote(params.row)
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            display: this.buttonRoot == "1001" ? "" : "none",
                                        },
                                    },
                                    "修改" //1001
                                ),
                                h(
                                    "Button",
                                    {
                                        attrs: {
                                            type: "info",
                                            size: "small",
                                        },
                                        on: {
                                            click: () => {
                                                this.orgCode = params.row.orgCode
                                                this.publish(params.row)
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            display: this.buttonRoot == "1002" && params.row.status == "待审核" ? "" : "none",
                                        },
                                    },
                                    "审核" //1002
                                ),
                                h(
                                    "Button",
                                    {
                                        attrs: {
                                            type: "info",
                                            size: "small",
                                        },
                                        on: {
                                            click: () => {
                                                this.orgCode = params.row.orgCode
                                                this.publish(params.row)
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            display: this.buttonRoot == "1003" && params.row.status == "待发布" ? "" : "none",
                                        },
                                    },
                                    "发布" //1003
                                ),
                                h(
                                    "Dropdown",
                                    {
                                        props: {
                                            transfer: true,
                                        },
                                    },
                                    [
                                        h(
                                            "Button",
                                            {
                                                props: {
                                                    type: "success",
                                                    size: "small",
                                                    icon: "ios-arrow-down",
                                                },
                                            },
                                            "操作"
                                        ),
                                        h(
                                            "DropdownMenu",
                                            {
                                                slot: "list",
                                            },
                                            [
                                                h(
                                                    "DropdownItem",
                                                    {
                                                        nativeOn: {
                                                            click: () => {
                                                                this.seeAnswer(params.row)
                                                            },
                                                        },
                                                        style: {
                                                            textAlign: "center",
                                                            // display: (this.buttonRoot == '1001') ? '' : 'none'
                                                        },
                                                    },
                                                    "查看"
                                                ),
                                                h(
                                                    "DropdownItem",
                                                    {
                                                        nativeOn: {
                                                            click: () => {
                                                                this.orgCode = params.row.orgCode
                                                                this.publish(params.row)
                                                            },
                                                        },
                                                        style: {
                                                            textAlign: "center",
                                                            display: params.row.status == "已发布" ? "" : "none",
                                                        },
                                                    },
                                                    "过期"
                                                ),
                                                h(
                                                    "DropdownItem",
                                                    {
                                                        nativeOn: {
                                                            click: () => {
                                                                this.deleteVote(params.row)
                                                            },
                                                        },
                                                        style: {
                                                            textAlign: "center",
                                                        },
                                                    },
                                                    "删除"
                                                ),
                                                h(
                                                    "DropdownItem",
                                                    {
                                                        nativeOn: {
                                                            click: () => {
                                                                this.answerRecord(params.row)
                                                            },
                                                        },
                                                        style: {
                                                            textAlign: "center",
                                                            display: params.row.status == "已发布" || params.row.status == "已过期" ? "" : "none",
                                                        },
                                                    },
                                                    "答题记录"
                                                ),
                                                h(
                                                    "DropdownItem",
                                                    {
                                                        nativeOn: {
                                                            click: () => {
                                                                this.winningList(params.row)
                                                            },
                                                        },
                                                        style: {
                                                            textAlign: "center",
                                                            display: params.row.status == "已发布" || params.row.status == "已过期" ? "" : "none",
                                                        },
                                                    },
                                                    "中奖名单"
                                                ),
                                                h(
                                                    "DropdownItem",
                                                    {
                                                        nativeOn: {
                                                            click: () => {
                                                                this.$router.push(`/readunread?type=answer&msgId=${params.row.id}&menuId=${this.$route.query.menuId}`)
                                                            },
                                                        },
                                                        style: {
                                                            textAlign: "center",
                                                            display: params.row.status == "已发布" || params.row.status == "已过期" ? "" : "none",
                                                        },
                                                    },
                                                    "已读未读"
                                                ),
                                            ]
                                        ),
                                    ]
                                ),
                            ]
                        )
                    },
                },
            ],
            tableData: [],
            total: 0,
            currentPage: 1,
            pageSize: 20,
            menuId: "",
            type: "",
            columnCode: "",
            //type,columnCode等接口数据,传给子组件
            menuCodeObj: {},
            status: "",
            //信息状态
            status1: 0,
            status2: 0,
            status3: 0,
            status4: 0,
            status5: 0,
            addChangeModalStatus: false,
            addChangeTitle: "",
            disableBtn: "0",
            //选中表格
            selectData: [],
            //推荐/排序
            recommend: "",
            infoId: "",
            sortNum: "",
            //活跃menu
            activeMenu: "",

            //排序定时器
            timer: null,

            // 存储当前信息的orgCode
            orgCode: "",
        }
    },
    methods: {
        tikuFn() {
            this.$router.push({
                path: "/answerquestion",
                query: {
                    menuId: this.$route.query.menuId,
                },
            })
        },
        //排序
        sortChangeFn(obj) {
            let params = {
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                infoId: this.infoId, //当前信息类id
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: this.orgCode, //parent.vue.loginInfo.userinfo.orgCode,
                sort: this.sortNum,
                status: this.status,
                recommend: this.recommend,
            }

            this.$post("/old/api/pc/affiche/emp/changeAfficheField", params)
                .then(res => {
                    if (res.code == 200) {
                        this.pageChange({
                            page: this.currentPage,
                            pageSize: this.pageSize,
                        })
                        this.$Message.success({
                            background: true,
                            content: "修改成功",
                        })
                    } else {
                        this.$Message.error({
                            background: true,
                            content: "修改失败",
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        //导出excel
        exportExcel() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>您正在导出当前页的数据，是否继续？（若要导出更多数据，请设置每页条数。）</p>",
                onOk: () => {
                    let tHeader = ["信息名称", "编码", "发布范围", "公布时间", "截至时间", "拟制人", "推荐", "排序", "审核状态"]
                    let filterVal = ["title", "code", "privilegeString", "startTime", "endTime", "creatorName", "recommend", "sort", "status"]
                    let tableData = JSON.parse(JSON.stringify(this.tableData))
                    tableData.map((item, index) => {
                        item.recommend = item.recommend == 1 ? "推荐" : "不推荐"
                    })
                    this.$core.exportExcel(tHeader, filterVal, tableData, "答题活动列表")
                },
            })
        },
        //表格选中
        tableSelect(arr) {
            this.selectData = arr
        },
        //批量删除
        moreDel() {
            if (this.selectData.length > 0) {
                let idStr = ""
                this.selectData.map((item, index) => {
                    idStr += item.id + ","
                })
                idStr = idStr.substring(0, idStr.lastIndexOf(","))
                this.deleteVote({ id: idStr })
            } else {
                this.$Message.error({
                    background: true,
                    content: "请选择需删除的信息",
                })
            }
        },

        //查看/修改/发布组件菜单栏
        comSelectMenu(value) {
            this.disableBtn = value
        },
        //查看弹窗
        seeModalModalFn(status) {
            this.seeModalStatus = status
        },
        //调用子组件的相应方法
        //上一步
        backStep() {
            if (this.addVoteStatus) {
                this.$refs.addVote.nextStep(-1)
            } else if (this.changeVoteStatus) {
                this.$refs.changeVote.nextStep(-1)
            } else {
                this.$refs.publishVote.nextStep(-1)
            }
        },

        //下一步
        nextStep() {
            if (this.addVoteStatus) {
                this.$refs.addVote.nextStep(1)
            } else if (this.changeVoteStatus) {
                this.$refs.changeVote.nextStep(1)
            } else {
                this.$refs.publishVote.nextStep(1)
            }
        },
        //驳回
        rejectVote() {
            this.$refs.publishVote.reject()
        },
        // 通过并发布按钮
        passAndSend() {
            this.$refs.publishVote.passAndSend()
        },
        //通过/发布/过期
        saveVote() {
            if (this.addVoteStatus) {
                this.$refs.addVote.save()
            } else if (this.changeVoteStatus) {
                this.$refs.changeVote.save()
            } else {
                this.$refs.publishVote.publish()
            }
        },
        contentFn() {
            //预览文本
            if (this.addVoteStatus) {
                this.$refs.addVote.contentFn()
            } else if (this.changeVoteStatus) {
                this.$refs.changeVote.contentFn()
            } else {
                // this.$refs.publishVote.publish();
                console.log("预览文本的查看选项")
            }
        },
        //查看
        seeAnswer(row) {
            this.$get("/voteapi/api/pc/affiche/getAfficheInfoManager", {
                infoId: row.id,
            }).then(res => {
                if (res.code == 200) {
                    this.seeFormData.city = res.dataList[0]
                }
            })
            this.$get("/voteapi/api/pc/affiche/emp/queryById", {
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                infoId: row.id,
            })
                .then(res => {
                    if (res.code == 200) {
                        this.seeFormData = {
                            title: "", //标题
                            source: "", //信息来源
                            subtype: "", //信息类型
                            appointmentPhone: "", //联系电话（预约号码）
                            startTime: "", //开始时间
                            subStartTime: "",
                            endTime: "", //结束时间
                            subEndTime: "",
                            provinceCode: 44, //省编码
                            cityCode: "", //城市编码
                            areaCode: "", //区县编码
                            streetCode: "", //街道编码
                            projectCodeList: "", //社区编码
                            city: "", //修改时地址
                            content: "", //富文本
                            photo: "", //封面图片上传路径
                            settingImgPath: "", //高级设置背景图片

                            voteTypeList: [], //投票方式列表

                            //规则配置
                            questionBankId: "", // 题库类型
                            userVerified: "0", //实名认证 0 不需要 1 需要
                            period: "", //周期设置 全程0 每天 24 小时 0-24 ,
                            answerNumber: "", //答题局数 ,
                            roundQuestionNumber: "", //每局题目数
                            questionLimit: "", //每题限时 s
                            participantsNumber: "", //参与人数
                            winnersNumber: "", //中奖人数
                            awardMethod: "", //评奖方式
                            effectiveTime: "", //有效领奖开始时间 ,
                            expirationTime: "", //有效领奖截止时间

                            valiStartTime: "", //每天答题起始时间 格式00:00:00 ,
                            valiEndTime: "", // 每天答题截止时间 格式00:00:00 ,
                            whetherComment: "0", //是否开启评论 0 否 1 是 ,
                            recommend: "0", // 是否推荐 0 否 1是
                            pushChannel: false, //小程序订阅通

                            modifier: parent.vue.loginInfo.userinfo.nickName, //修改人
                            staffId: parent.vue.loginInfo.userinfo.id,
                            status: 1,
                            creatorId: parent.vue.loginInfo.userinfo.id,
                        }
                        for (let k in res.data) {
                            if (this.seeFormData.hasOwnProperty(k)) {
                                this.seeFormData[k] = res.data[k]
                            }
                            if (k == "answer") {
                                for (let j in res.data.answer) {
                                    if (this.seeFormData.hasOwnProperty(j)) {
                                        if (j == "endTime" || j == "startTime") continue
                                        this.seeFormData[j] = res.data.answer[j]
                                    }
                                    if (j == "ruleVo") {
                                        for (let i in res.data.answer.ruleVo) {
                                            if (this.seeFormData.hasOwnProperty(i)) {
                                                this.seeFormData[i] = res.data.answer.ruleVo[i]
                                            }
                                        }
                                    }
                                }
                            }
                            if (k == "manager" && res.data.manager) {
                                res.data.manager.map((item, index) => {
                                    if (index == 0) {
                                        this.seeFormData.city += item
                                    } else {
                                        this.seeFormData.city += "、" + item
                                    }
                                })
                            }
                        }

                        this.seeFormData.pushChannel = this.seeFormData.pushChannel === "4" ? true : false

                        this.seeFormData.startTime = format(this.seeFormData.startTime, "yyyy-MM-dd HH:mm:ss")
                        this.seeFormData.endTime = format(this.seeFormData.endTime, "yyyy-MM-dd HH:mm:ss")
                        this.seeFormData.effectiveTime = format(this.seeFormData.effectiveTime, "yyyy-MM-dd")
                        this.seeFormData.expirationTime = format(this.seeFormData.expirationTime, "yyyy-MM-dd")

                        //查询题库类型
                        this.$get("/voteapi/api/pc/componentQuestion/selectQuestionBank", {
                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                        }).then(res => {
                            if (res.code == 200) {
                                res.dataList.map((item, index) => {
                                    if (this.seeFormData.questionBankId == item.questionBankId) {
                                        this.seeFormData.questionBankId = item.questionBankType
                                    }
                                })
                            }
                        })

                        //获取信息类型
                        this.$get("/voteapi/api/pc/menu/getMenuParam", {
                            columnCode: this.$core.getUrlParam("menuId") || "",
                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                        }).then(res => {
                            res.map((item, index) => {
                                if (this.seeFormData.subtype == item.value) {
                                    this.seeFormData.subtype = item.label
                                }
                            })
                        })
                        console.log(this.seeFormData)
                        this.seeModalStatus = true
                    } else {
                        this.$Message.error({
                            background: true,
                            content: "获取答题信息失败！",
                        })
                    }
                })
                .catch(err => {
                    this.$Message.error({
                        background: true,
                        content: "获取答题信息失败！",
                    })
                })
        },
        //新增/修改弹窗状态改变
        addChangeModalFn(status) {
            if (!status) {
                this.addVoteStatus = false
                this.changeVoteStatus = false
                this.publishVoteStatus = false
            }
            this.disableBtn = "0"
            this.addChangeModalStatus = status
        },
        //左侧信息菜单
        selectMenu(name) {
            if (name == "7") {
                this.status = ""
            } else {
                this.status = name
            }
            this.currentPage = 1
            this.pageChange({
                page: this.currentPage,
                pageSize: this.pageSize,
            })
        },
        //新增
        addVote() {
            this.addVoteStatus = true
            this.changeVoteStatus = false
            this.addChangeTitle = "新增答题"
            this.addChangeModalStatus = true
        },
        //修改
        changeVote(value) {
            this.infoId = value.id
            this.addVoteStatus = false
            this.changeVoteStatus = true
            this.addChangeTitle = "修改答题"
            this.addChangeModalStatus = true
        },
        //审核/发布/过期
        publish(value) {
            this.infoId = value.id
            this.addVoteStatus = false
            this.changeVoteStatus = false
            this.addChangeModalStatus = true
            this.publishVoteStatus = true
        },
        //动态修改审核/发布/过期title
        modalStatusFn(status) {
            this.publishRightBtnStatus = status
            if (status == "1") {
                this.addChangeTitle = "审核答题"
            } else if (status == "3") {
                this.addChangeTitle = "发布答题"
            } else {
                this.addChangeTitle = "过期答题"
            }
        },
        //删除
        deleteVote(row) {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>确定删除该信息？</p>",
                onOk: () => {
                    this.$get("/voteapi/api/pc/affiche/emp/deleteAfficheInfo", {
                        operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                        orgCode: parent.vue.loginInfo.userinfo.orgCode,
                        oemCode: parent.vue.oemInfo.oemCode,
                        businessType: this.menuCodeObj.businessType,
                        functionType: this.menuCodeObj.functionType,
                        infoId: row.id,
                    }).then(res => {
                        if (res.code == 200) {
                            this.$Message.success({
                                background: true,
                                content: "删除成功！",
                            })
                            this.pageChange({
                                page: this.currentPage,
                                pageSize: this.pageSize,
                            })
                        } else {
                            this.$Message.error({
                                background: true,
                                content: "删除失败！",
                            })
                        }
                    })
                },
            })
        },
        //答题记录
        answerRecord(row) {
            this.$router.push({
                path: "/answerrecord",
                query: {
                    id: row.id,
                    menuId: this.menuId,
                },
            })
        },
        //中奖名单
        winningList(row) {
            this.$router.push({
                path: "/answerwinning",
                query: {
                    id: row.id,
                    menuId: this.menuId,
                },
            })
        },
        pageChange(obj) {
            this.loading = true
            this.$get("/voteapi/api/pc/affiche/emp/getDataList", {
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                type: this.type,
                page: obj.page,
                pageSize: obj.pageSize,
                status: this.status,
                columnCode: this.columnCode,
            })
                .then(res => {
                    if (res.code == 200) {
                        //获取信息状态的数量
                        this.messageStatusCount()
                        this.total = res.maxCount
                        this.currentPage = res.currentPage
                        this.pageSize = res.pageSize

                        //修改res数据格式
                        res.dataList.map((item, index, arr) => {
                            arr[index].startTime = format(item.startTime, "yyyy-MM-dd HH:mm:ss")
                            arr[index].endTime = format(item.endTime, "yyyy-MM-dd HH:mm:ss")

                            switch (item.status) {
                                case "1":
                                    arr[index].status = "待审核"
                                    break
                                case "2":
                                    arr[index].status = "待提交/已驳回"
                                    break
                                case "3":
                                    arr[index].status = "待发布"
                                    break
                                case "4":
                                    arr[index].status = "已发布"
                                    break
                                case "5":
                                    arr[index].status = "已过期"
                                    break
                                default:
                                    arr[index].status = "已归档"
                            }
                        })
                        this.tableData = res.dataList
                        this.loading = false
                    } else {
                        this.$Message.error({
                            background: true,
                            content: "获取数据失败",
                        })
                        this.loading = false
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        //获取信息状态的数量
        messageStatusCount() {
            this.$get("/voteapi/api/pc/affiche/emp/groupStatus", {
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                type: this.type,
                columnCode: this.columnCode,
            }).then(res => {
                if (res.code == 200) {
                    this.status1 = res.data.auditNumber
                    this.status2 = res.data.rejectNumber
                    this.status3 = res.data.waitNumber
                    // this.status4 = res.data.rejectNumber;  //草稿箱数量
                }
            })
        },
    },
    async created() {
        let userButtonRoot = parent.vue.userButtonRoot
        this.buttonRoot = userButtonRoot.includes("1001") ? "1001" : userButtonRoot.includes("1002") ? "1002" : userButtonRoot.includes("1003") ? "1003" : ""
        // this.buttonRoot = "1003";
        this.status = this.buttonRoot == "1001" ? "2" : this.buttonRoot == "1002" ? "1" : this.buttonRoot == "1003" ? "3" : ""
        //默认选中第一项菜单
        this.activeMenu = this.status
        this.menuId = this.$core.getUrlParam("menuId") || ""

        await this.$get("/old/api/pc/menu/selectColumnByMenuCode", {
            menuCode: this.menuId,
        }).then(res => {
            if (res.code == 200) {
                this.type = res.data.columnType
                this.columnCode = res.data.columnCode
                this.menuCodeObj = res.data
            } else {
                this.$Message.error({
                    background: true,
                    content: res.desc,
                })
            }
        })

        this.pageChange({
            page: 1,
            pageSize: 20,
        })
    },
    components: {
        BaseInfo,
        RuleConfig,
        HighSetting,
        LiefengTable,
        LiefengContent,
        LiefengModal,
        AddVote,
        ChangeVote,
        PublishVote,
    },
}
</script>

<style scoped lang='less'>
.table-left {
    float: left;
    width: 160px;
    /deep/.ivu-menu {
        width: 160px !important;
    }
}
/deep/.ivu-tooltip-inner {
    width: 200px;
    white-space: normal;
}
/deep/.ivu-menu-item {
    z-index: 3;
}
</style>
