<template>
  <div class="high-setting">
    <Form
      ref="formValidate"
      :model="formValidate"
      :label-width="100"
      :label-colon="true"
      :rules="ruleValidate"
      :inline="true"
    >
      <FormItem label="每天答题起始">
         <span>{{formValidate.valiStartTime !="" ? formValidate.valiStartTime : "无"}}</span> - <span>{{formValidate.valiEndTime != '' ? formValidate.valiEndTime : "无"}}</span>
      </FormItem>
      <!-- 暂时不加 -->
      <!-- <FormItem label="成功分享邀请新用户" prop="openDiscuss">
        <RadioGroup v-model="formValidate.openDiscuss">
          <Radio :label="item.dictKey" v-for="(item,index) in formValidate.openDiscussList" :key="index">{{item.dictValue}}</Radio>
        </RadioGroup>
        <div style="margin-left: 10px;display: inline-block" v-if="formValidate.openDiscuss == formValidate.openDiscussList[0].dictKey">
          <InputNumber :max="10" :min="1" v-model="formValidate.second"></InputNumber>
          <span style="margin-left: 10px">次答题机会</span>
        </div>
      </FormItem> -->
       <FormItem label="评论" >
         <p>{{formValidate.whetherComment == '1' ? '开启' : (formValidate.whetherComment == '2' ? '关闭' : '无')}}</p>
      </FormItem>
       <!-- 暂时不加 -->
      <!-- <FormItem label="封面图片" class="form-item" style="width: 100%">
         <img ref="image" src="" alt=""  width="200">
        <Upload
            :before-upload="beforeImage"
            :show-upload-list="false"
            :action="actionImage"
            :data="uploadImageData"
            :on-success="(value, file) => imageSuccess(file)"
            :format="['jpg', 'jpeg', 'png']"
            :on-format-error="imageError"
          >
            <Button type="info">上传图片</Button>
          </Upload>
      </FormItem> -->
      
    </Form>
  </div>
</template>

<script>
export default {
  props: ["formValidate", "ruleValidate"],
  data() {
    return {
      formData: {},
    };
  },
  methods: {
   
  },
};
</script>
    
<style scoped lang='less'>
/deep/.ivu-form-item {
  width: 32%
}
</style>