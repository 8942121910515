<template>
  <div class="rule">
    <div class="vote-rule">
       <Card :bordered="true">
         <p slot="title" style="font-size:14px;">答题规则区域</p>
      <Form
        :model="formValidate"
        :label-colon="true"
        :label-width="100"
        ref="formValidate"
        :inline="true"
      >
        <FormItem label="题库类型">
          <p>{{formValidate.questionBankId !='' ? formValidate.questionBankId : '无'}}</p>
        </FormItem>
        <FormItem label="实名验证">
          <p>{{formValidate.userVerified == 1 ? '是' : '否'}}</p>
        </FormItem>
        <FormItem label="每局题目数">
          <p>{{formValidate.roundQuestionNumber != '' ? (formValidate.roundQuestionNumber + '条') : '无'}}</p>
        </FormItem>
        <FormItem label="每题限时">
          <p>{{formValidate.questionLimit!='' ?  (formValidate.questionLimit+ '秒') : '无'}}</p>
        </FormItem>
        <FormItem label="参与人数">
          <p>{{formValidate.participantsNumber != '' ? (formValidate.participantsNumber + '人') : '无'}}</p>
        </FormItem>
        <FormItem label="中奖人数">
          <p>{{formValidate.winnersNumber !=''? (formValidate.winnersNumber + '人') : '无'}}</p>
        </FormItem>
        <FormItem label="评奖方式">
          <p>{{formValidate.awardMethod == '1' ? '用时最短排行' : '无'}}</p>
        </FormItem>
        <FormItem label="有效领奖时间">
          <p>{{formValidate.effectiveTime}} 至 {{formValidate.expirationTime}}</p>
        </FormItem>

        <!-- <FormItem label="周期设置" prop="voteCycle">
          <RadioGroup v-model="formValidate.voteCycle">
            <Radio :label="item.dictKey" v-for="(item,index) in formValidate.voteCycleList" :key="index">{{item.dictValue}}</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem
          label="【单选】票数设置"
          class="number-vote"
          v-if="formValidate.voteType == '1'"
        >
          <div class="number-vote-setting">
            <span>每个用户可以投</span>
            <FormItem prop="voteMaxAccount">
              <InputNumber
                :min="1"
                v-model="formValidate.voteMaxAccount"
              ></InputNumber>
            </FormItem>
            <span>次</span>
          </div>

          <div class="number-vote-setting">
            <span>可为同一选手投</span>
            <FormItem prop="voteMaxItem">
              <InputNumber
                :min="1"
                v-model="formValidate.voteMaxItem"
              ></InputNumber>
            </FormItem>
            <span>次</span>
          </div>
        </FormItem>
        <FormItem
          label="【多选】票数设置"
          class="number-vote"
          v-else
        >
          <div class="number-vote-setting">
            <span>最少选择</span>
            <FormItem prop="selectMinItem">
              <InputNumber
                :min="1"
                v-model="formValidate.selectMinItem"
              ></InputNumber>
            </FormItem>
            <span>个选项</span>
          </div>
          <div class="number-vote-setting">
            <span>最多选择</span>
            <FormItem prop="selectMaxItem">
              <InputNumber
                :min="1"
                v-model="formValidate.selectMaxItem"
              ></InputNumber>
            </FormItem>
            <span>个选项</span>
          </div>
        </FormItem> -->

        <!-- 这是高级配置的内容，暂时迁移到这里，后面如果需要再使用高级配置组件 -->
        <FormItem label="每天答题起始">
          <span>{{ formValidate.valiStartTime != "" ? formValidate.valiStartTime : "无" }}</span> -
          <span>{{ formValidate.valiEndTime != '' ? formValidate.valiEndTime : "无" }}</span>
        </FormItem>
        <FormItem label="评论">
          <p>{{ formValidate.whetherComment == '1' ? '开启' : (formValidate.whetherComment == '2' ? '关闭' : '无') }}</p>
        </FormItem>
        <FormItem label="是否推荐">
          <p>{{ formValidate.recommend == '1' ? '是' : (formValidate.recommend == '0' ? '否' : '无') }}</p>
        </FormItem>
          <FormItem label="推送方式">
          <p>{{ formValidate.pushChannel===true? '小程序订阅通知' : (formValidate.pushChannel===false ? '未推送' : '无') }}</p>
        </FormItem>
      </Form>
       </Card>
    </div>
  </div>
</template>

<script>
export default {
  props: ["formValidate", "ruleValidate"],
  data() {
    return {

    };
  },
  methods: {

  }
};
</script>

<style scoped lang='less'>
.ivu-form-item {
  width: 32%
}
.rule {
  margin-top: 20px;
}

</style>
